<template>
  <div class="yz_box" v-if="yanzheng">
    <!-- 头部 -->
    <section class="yz_header">
      号源：{{haoyuanName}}
      <span class="qicispan">期次:</span>
      <select v-model="currentExpect" style="width:200px" @change="updateQici">
        <option v-for="item in qiciList" :value="item.Expect" :key="item.Expect">{{item.Expect}}</option>
      </select>
      <div class="guanbi" @click="closeYanzheng">关闭</div>
    </section>
    <!--  -->
    <div>
      <!-- kl8 (PCDD)    号码 -->
      <div class="haoma">
        <p v-for="(code,index) in codeList" :class="Number(code)>40?'senseball':'greyball'" :key="index">{{code}}</p>
      </div>
      <hr>
      <!-- lhc 算法 -->
      <div class="pk10_suanfa type-lhc" v-if="currentGametype=='hk6'">
        <div class="pk10_row">
          <span class="sx">1</span>
          <span class="r_label">显示完整号源:</span>
          <div class="pk_con1" id="pk_con1">{{lhc1}}</div>
          <span @click="clickCopy(lhc1)">点击复制</span>
        </div>
        <div class="pk10_row">
          <span class="sx">2</span>
          <span class="r_label">SHA512转化值:</span>
          <p class="pk10_con2">
            <span class="red">{{lhc22}}</span>{{lhc2}}
          </p>
        </div>
        <div class="pk10_row">
          <span class="sx">3</span>
          <span class="r_label">取值:1-8,3-10位依次循环共取49份:</span>
          <div class="pk_con3">
            <template v-for="item in lhcarr3">

              <section class="small" :key="item.value">{{item.index}}</section>
              <section class="large" :key="item.value+2">{{item.value}}</section>

            </template>

          </div>
        </div>
        <div class="pk10_row">
          <span class="sx">4</span>
          <span class="r_label">每份16进制转化为10进制:</span>
          <div class="pk_con3">
            <template v-for="item in lhcarr4">
              <section class="small" :key="item.value+1">{{item.index}}</section>
              <section class="large" :key="item.value+2">{{item.value}}</section>

            </template>

          </div>
        </div>
        <div class="pk10_row ">
          <span class="sx">5</span>
          <span class="r_label">对49份数值进行大小排序:</span>
          <div class="pk_con3  ">
            <template v-for="item in lhcarr5">

              <section class="small " :key="item.value+1">
                <p :class="colorArr[item.index-1]+'ball b'+item.index"></p>
              </section>
              <section class="large" :key="item.value+2">{{item.value}}</section>

            </template>

          </div>
        </div>
        <div class="pk10_row">
          <span class="sx">6</span>
          <span class="r_label">取前七位得出开奖号码:</span>
          <div class="lhc_con4">
            <template v-for="(item,index) in lhcarr5">

              <p v-if="index<6" :class="colorArr[item.index-1]+'ball b'+item.index" :key="item.index"></p>
              <template v-if="index==6">
                <span :key="item.index+24" class="addbiaoshi">+</span>
                <p :class="colorArr[item.index-1]+'ball b'+item.index" :key="item.index"></p>

              </template>

            </template>

          </div>
        </div>

      </div>
      <!-- 11选5 算法 -->
      <div class="pk10_suanfa type-ssg " v-if="currentGametype=='11x5'">
        <div class="pk10_row">
          <span class="sx">1</span>
          <span class="r_label">显示完整号源:</span>
          <div class="pk_con1" id="pk_con1">{{syxw1}}</div>
          <span @click="clickCopy(syxw1)">点击复制</span>
        </div>
        <div class="pk10_row">
          <span class="sx">2</span>
          <span class="r_label">SHA512转化值:</span>
          <p class="pk10_con2">
            <span class="red">{{syxw22}}</span>{{syxw2}}
          </p>
        </div>
        <div class="pk10_row">
          <span class="sx">3</span>
          <span class="r_label">取前77位数字分为11份:</span>
          <div class="pk_con3">
            <template v-for="item in syxwarr3">

              <section class="small" :key="item.value">{{item.index}}</section>
              <section class="large" :key="item.value+2">{{item.value}}</section>

            </template>

          </div>
        </div>
        <div class="pk10_row">
          <span class="sx">4</span>
          <span class="r_label">每份16进制转化为10进制:</span>
          <div class="pk_con3">
            <template v-for="item in syxwarr4">
              <section class="small" :key="item.value+1">{{item.index}}</section>
              <section class="large" :key="item.value+2">{{item.value}}</section>

            </template>

          </div>
        </div>
        <div class="pk10_row ">
          <span class="sx">5</span>
          <span class="r_label">对11份数值进行大小排序:</span>
          <div class="pk_con3  ">
            <template v-for="item in syxwarr5">

              <section class="small " :key="item.value+1">
                <p :class="'blueball b'+item.index"></p>
              </section>
              <section class="large" :key="item.value+2">{{item.value}}</section>

            </template>

          </div>
        </div>
        <div class="pk10_row">
          <span class="sx">6</span>
          <span class="r_label">取前五位得出开奖号码:</span>
          <div class="klsf_con4">
            <template v-for="(item,index) in syxwarr5">

              <p v-if="index<5" :class="'blueball b'+item.index" :key="item.index"></p>

            </template>

          </div>
        </div>

      </div>
      <!-- klsf 算法 -->
      <div class="pk10_suanfa type-ssg " v-if="currentGametype=='klsf8'">
        <div class="pk10_row">
          <span class="sx">1</span>
          <span class="r_label">显示完整号源:</span>
          <div class="pk_con1" id="pk_con1">{{klsf1}}</div>
          <span @click="clickCopy(klsf1)">点击复制</span>
        </div>
        <div class="pk10_row">
          <span class="sx">2</span>
          <span class="r_label">SHA512转化值:</span>
          <p class="pk10_con2">
            <span class="red">{{klsf22}}</span>{{klsf2}}
          </p>
        </div>
        <div class="pk10_row">
          <span class="sx">3</span>
          <span class="r_label">取前100位数字分为20份:</span>
          <div class="pk_con3">
            <template v-for="item in klsfarr3">

              <section class="small" :key="item.value+1">{{item.index}}</section>
              <section class="large" :key="item.value+2">{{item.value}}</section>

            </template>

          </div>
        </div>
        <div class="pk10_row">
          <span class="sx">4</span>
          <span class="r_label">每份16进制转化为10进制:</span>
          <div class="pk_con3">
            <template v-for="item in klsfarr4">
              <section class="small" :key="item.value+8">{{item.index}}</section>
              <section class="large" :key="item.value+9">{{item.value}}</section>

            </template>

          </div>
        </div>
        <div class="pk10_row ">
          <span class="sx">5</span>
          <span class="r_label">对20份数值进行大小排序:</span>
          <div class="pk_con3  ">
            <template v-for="item in klsfarr5">

              <section class="small " :key="item.value+3">
                <p :class="Number(item.value)>18?'redball b'+item.index:'blueball b'+item.index"></p>
              </section>
              <section class="large" :key="item.value+5">{{item.value}}</section>

            </template>

          </div>
        </div>
        <div class="pk10_row">
          <span class="sx">6</span>
          <span class="r_label">取前八位得出开奖号码:</span>
          <div class="klsf_con4">
            <template v-for="(item,index) in klsfarr5">

              <p v-if="index<8" :class="Number(item.value)>18?'redball b'+item.index:'blueball b'+item.index" :key="item.index"></p>

            </template>

          </div>
        </div>

      </div>
      <!-- pk10算法 -->
      <div class="pk10_suanfa" v-if="currentGametype=='pk10'">
        <div class="pk10_row">
          <span class="sx">1</span>
          <span class="r_label">显示完整号源:</span>
          <div class="pk_con1" id="pk_con1">{{pk1}}</div>
          <span @click="clickCopy(pk1)">点击复制</span>
        </div>
        <div class="pk10_row">
          <span class="sx">2</span>
          <span class="r_label">SHA512转化值:</span>
          <p class="pk10_con2">
            <span class="red">{{pk22}}</span>{{pk2}}
          </p>
        </div>
        <div class="pk10_row">
          <span class="sx">3</span>
          <span class="r_label">取前60位数字分为10份:</span>
          <div class="pk_con3">
            <template v-for="item in pkarr3">

              <section class="small" :key="item.value+1">{{item.index}}</section>
              <section class="large" :key="item.value+2">{{item.value}}</section>

            </template>

          </div>
        </div>
        <div class="pk10_row">
          <span class="sx">4</span>
          <span class="r_label">每份16进制转化为10进制:</span>
          <div class="pk_con3">
            <template v-for="item in pkarr4">
              <section class="small" :key="item.value+1">{{item.index}}</section>
              <section class="large" :key="item.value+2">{{item.value}}</section>

            </template>

          </div>
        </div>
        <div class="pk10_row type-pks ">
          <span class="sx">5</span>
          <span class="r_label">对10份数值进行大小排序:</span>
          <div class="pk_con3 balls_pk ">
            <template v-for="item in pkarr5">

              <section class="small " :key="item.value+1">
                <p :class="'b'+item.index"></p>
              </section>
              <section class="large" :key="item.value+2">{{item.value}}</section>

            </template>

          </div>
        </div>
        <div class="pk10_row type-pks ">
          <span class="sx">6</span>
          <span class="r_label">得出开奖号码:</span>
          <div class="pk_con4 balls_pk ">
            <p :class="'b'+item.index" v-for="item in pkarr5" :key="item.index"></p>

          </div>
        </div>

      </div>
      <!-- ssc 算法 -->
      <div class="ssc_suanfa" v-if="currentGametype=='ssc'">
        <table class="com-table" v-for="(itemarr,index) in sscData" :key="index">
          <tr>
            <th v-for="(tstr,ti) in itemarr[0]" :key="ti" :class="ti<7?'w80':''">{{tstr}}</th>

          </tr>
          <tr>

            <td v-for="(num,index) in itemarr[1]" :key="index" class="type-ssc">
              <!-- {{num}}-{{index}} -->
              <!-- &&index!=5 -->
              <p v-if="index>=1&&index<=6" :class="Number(num)>40?'senseball':'greyball'">{{num}}</p>
              <p v-else-if="index==8" :class="'blueball b'+Number(num)"></p>
              <span v-else-if="index==7" class="red">{{num}}</span>
              <span v-else>{{num}}</span>
            </td>
          </tr>
        </table>

        <div class="type-ssc ssc_result">第 <span class="red">{{currentExpect}}</span> 期开奖结果： <p :class="'blueball b'+Number(item)" :key="item" v-for="item in weishuArr"></p>
        </div>

      </div>
      <!-- pcdd 算法 -->
      <div class="pcdd_suanfa" v-if="currentGametype=='pcdd'">
        <section class="pcdd_row">
          <p class="bankuang"></p>
          <p class="bankuang"></p>
          <p class="bankuang"></p>
        </section>
        <section class="pcdd_row">
          <p class="bankuang nobor">1-6位和数: <span class="red">{{he1}}</span></p>
          <p class="bankuang nobor">7-12位和数:<span class="red">{{he2}}</span></p>
          <p class="bankuang nobor">13-18位和数: <span class="red">{{he3}}</span> </p>
        </section>
        <section class="pcdd_row">
          <p class="bankuang nobor"><span class="line_th"></span></p>
          <p class="bankuang nobor"><span class="line_th"></span></p>
          <p class="bankuang nobor"><span class="line_th"></span></p>
        </section>
        <section class="pcdd_row">
          <p class="bankuang nobor">
            <span class="moweishu">末位数: <span class="red">{{mo1}}</span> <span class="after">+</span> </span>
          </p>
          <p class="bankuang nobor"><span class="moweishu">末位数: <span class="red">{{mo2}}</span> <span class="after">+</span> </span></p>
          <p class="bankuang nobor">
            <span class="moweishu">末位数: <span class="red">{{mo3}}</span> </span>
          </p>
        </section>
        <section class="pcdd_row center mt20">
          <p class="bankuang w500"></p>
        </section>
        <section class="pcdd_row center nomt">
          <p class="bankuang nobor">
            <span class="line_th zh"></span>
          </p>
        </section>
        <section class="pcdd_row center">
          <p class="bankuang nobor w500">三个末位数之和就是{{currentGame.Gamename}}第 <span class="red">{{currentExpect}}</span>期开奖结果：</p>
        </section>
        <section class="pcdd_row center pcddopen" style="margin-top:20px">
          <p :class="'ball b0'">{{ mo1 }}</p>
          <p :class="'ball b1'">{{ mo2 }}</p>
          <p :class="'ball b2'">{{ mo3 }}</p>
          <p :class="'pcddball b'+mohe"></p>
        </section>
      </div>
      <!-- k3 算法-->
      <div class="pcdd_suanfa" v-if="currentGametype=='k3'">
        <section class="pcdd_row">
          <p class="bankuang"></p>
          <p class="bankuang"></p>
          <p class="bankuang"></p>
        </section>
        <section class="pcdd_row">
          <p class="bankuang nobor">1-6位和数: <span class="red">{{he1}}</span></p>
          <p class="bankuang nobor">7-12位和数:<span class="red">{{he2}}</span></p>
          <p class="bankuang nobor">13-18位和数: <span class="red">{{he3}}</span> </p>
        </section>
        <section class="pcdd_row">
          <p class="bankuang nobor"><span class="line_th"></span></p>
          <p class="bankuang nobor"><span class="line_th"></span></p>
          <p class="bankuang nobor"><span class="line_th"></span></p>
        </section>
        <section class="pcdd_row">
          <p class="bankuang nobor">
            <span class="moweishu">除以6余: <span class="red">{{mo1}}</span> </span>
          </p>
          <p class="bankuang nobor"><span class="moweishu">除以6余: <span class="red">{{mo2}}</span> </span></p>
          <p class="bankuang nobor">
            <span class="moweishu">除以6余: <span class="red">{{mo3}}</span> </span>
          </p>
        </section>
        <section class="pcdd_row center mt20">
          <p class="bankuang w500"></p>
        </section>
        <section class="pcdd_row center nomt">
          <p class="bankuang nobor">
            <span class="line_th zh"></span>
          </p>
        </section>
        <section class="pcdd_row center">
          <p class="bankuang nobor w500">三个余数就是{{currentName}}第 <span class="red">{{currentExpect}}</span>期开奖结果：</p>
        </section>
        <section class="pcdd_row center type-s3s" style="margin-top:20px">
          <p :class="'numball b'+mo1"></p>
          <p :class="'numball b'+mo2" style="margin:0 5px"></p>
          <p :class="'numball b'+mo3"></p>

        </section>
      </div>

    </div>
    <!-- <p v-if="he1==0&&weishuArr.length==0&&!isBingGo" class="zhushi">采用【体彩排列五】官方结果通过哈希值运算，公平公正，可复制号码至任何公网校验！</p> -->
    <!-- &&isBingGo -->
    <p v-if="he1==0&&weishuArr.length==0" class="zhushi">采用台湾【BINGO宾果】官方结果通过哈希值运算，公平公正，可复制号码至任何公网校验！</p>
    <p v-if="he1>0||weishuArr.length>0" class="zhushi">采用台湾【BINGO宾果】彩官方结果计算，公平公正，可至任何公网校验！</p>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CryptoJS from "crypto-js";
export default {
  name: "",
  props: [""],
  data() {
    return {
      haoyuanName: "",
      qiciList: [],
      currentExpect: "",
      codeList: [],
      // lhc 用到的数据
      lhc1: "",
      lhc2: "",
      lhc22: "",
      lhcarr3: [],
      lhcarr4: [],
      lhcarr5: [],
      lhcSpliceIndex: [
        [0, 8],
        [2, 10],
        [4, 12],
        [6, 14],
        [8, 16],
        [10, 18],
        [12, 20],
        [14, 22],
        [16, 24],
        [18, 26],
        [20, 28],
        [22, 30],
        [24, 32],
        [26, 34],
        [28, 36],
        [30, 38],
        [32, 40],
        [34, 42],
        [36, 44],
        [38, 46],
        [40, 48],
        [42, 50],
        [44, 52],
        [46, 54],
        [48, 56],
        [50, 58],
        [52, 60],
        [54, 62],
        [56, 64],
        [58, 66],
        [60, 68],
        [62, 70],
        [64, 72],
        [66, 74],
        [68, 76],
        [70, 78],
        [72, 80],
        [74, 82],
        [76, 84],
        [78, 86],
        [80, 88],
        [82, 90],
        [84, 92],
        [86, 94],
        [88, 96],
        [90, 98],
        [92, 100],
        [94, 102],
        [96, 104],
        // [98, 106],
      ],
      // 11选5 用到的数据
      syxw1: "",
      syxw2: "",
      syxw22: "",
      syxwarr3: [],
      syxwarr4: [],
      syxwarr5: [],
      syxwspliceIndex: [0, 7, 14, 21, 28, 35, 42, 49, 56, 63, 70],
      // 快乐十分 用到的数据
      klsf1: "",
      klsf2: "",
      klsf22: "",
      klsfarr3: [],
      klsfarr4: [],
      klsfarr5: [],
      klsfspliceIndex: [
        0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85,
        90, 95,
      ],
      //   pk10 用到的数据
      pk1: "",
      pk2: "",
      pk22: "",
      pkarr3: [],
      pkarr4: [],
      pkarr5: [],
      splicehashArr: [0, 6, 12, 18, 24, 30, 36, 42, 48, 54],
      //  pcdd k3  用到的数据
      he1: 0,
      he2: 0,
      he3: 0,
      mo1: 0,
      mo2: 0,
      mo3: 0,
      mohe: 0,
      //   时时彩  算法 数据
      sscData: [],
      weishuArr: [],
      sscArr: [
        [
          [
            "",
            "取第一位",
            "取第八位",
            "取第十位",
            "取第十一位",
            "取第十三位",
            "取第二十位",
            "和数",
            "尾数",
          ],
          [],
          [0, 7, 9, 10, 12, 19],
        ],
        [
          [
            "",
            "取第二位",
            "取第五位",
            "取第八位",
            "取第十三位",
            "取第十六位",
            "取第十九位",
            "和数",
            "尾数",
          ],
          [],
          [1, 4, 7, 12, 15, 18],
        ],
        [
          [
            "",
            "取第三位",
            "取第七位",
            "取第九位",
            "取第十二位",
            "取第十四位",
            "取第十八位",
            "和数",
            "尾数",
          ],
          [],
          [2, 6, 8, 11, 13, 17],
        ],
        [
          [
            "",
            "取第四位",
            "取第五位",
            "取第九位",
            "取第十二位",
            "取第十六位",
            "取第十七位",
            "和数",
            "尾数",
          ],
          [],
          [3, 4, 8, 11, 15, 16],
        ],
        [
          [
            "",
            "取第六位",
            "取第七位",
            "取第十位",
            "取第十一位",
            "取第十四位",
            "取第十五位",
            "和数",
            "尾数",
          ],
          [],
          [5, 6, 9, 10, 13, 14],
        ],
      ],
      sscArr1: [
        [
          ["", "取第一位", "取第二位", "取第三位", "取第四位", "和数", "尾数"],
          [],
          [0, 1, 2, 3],
        ],
        [
          ["", "取第五位", "取第六位", "取第七位", "取第八位", "和数", "尾数"],
          [],
          [4, 5, 6, 7],
        ],
        [
          [
            "",
            "取第九位",
            "取第十位",
            "取第十一位",
            "取第十二位",
            "和数",
            "尾数",
          ],
          [],
          [8, 9, 10, 11],
        ],
        [
          [
            "",
            "取第十三位",
            "取第十四位",
            "取第十五位",
            "取第十六位",
            "和数",
            "尾数",
          ],
          [],
          [12, 13, 14, 15],
        ],
        [
          [
            "",
            "取第十七位",
            "取第十八位",
            "取第十九位",
            "取第二十位",
            "和数",
            "尾数",
          ],
          [],
          [16, 17, 18, 19],
        ],
      ],
      isBingGo: true,
      zhanweiArr: ["万位", "千位", "百位", "十位", "个位"],
      //   --------------------------------------------------
    };
  },
  watch: {
    kk() {
      if (this.yanzheng) {
        this.getQici();
      }
    },
    yanzheng(val) {
      if (val) {
        this.getQici();
      }
    },
    codeList: {
      deep: true,
      handler(arr) {
        // lhc 算法
        if (this.currentGametype == "hk6") {
          this.lhcComputed();
        }
        // 11选5 算法
        if (this.currentGametype == "11x5") {
          this.syxwComputed();
        }
        // klsf8 算法
        if (this.currentGametype == "klsf8") {
          this.klsf8Computed();
        }
        // pk10算法
        if (this.currentGametype == "pk10") {
          this.pk10Computed();
        }
        // pcdd的算法-----------------------------------------------------
        if (this.currentGametype == "pcdd") {
          this.pcddComputed();
        }
        // 快3 算法
        if (this.currentGametype == "k3") {
          this.k3Computed();
        }
        // ssc算法
        if (this.currentGametype == "ssc") {
          if (this.currentroomeng == "bingossc") {
            this.sscData = this.sscArr;
            this.sscComputed();
          }
          if (this.currentroomeng == "tw5fc") {
            this.sscData = this.sscArr1;
            this.sscComputed1();
          }
        }
      },
    },
  },
  components: {},
  created() {},
  computed: {
    ...mapState([
      "userInfo",
      "currentGame",
      "yanzheng",
      "currentroomeng",
      "currentGametype",
      "currentFrequency",
      "currentName",
      "currentroomid",
      "kk",
    ]),
  },

  mounted() {},

  methods: {
    /**
     * 获取文件SHA-512的值
     * @params data  读取文件的数据，ArrarBuffer格式
     * async   await
     */
    async getSha512(data) {
      // 读取文件时，直接将文件读取为Arryabuffer的形式。顾不需要进行 encode as (utf-8) Uint8Array
      // 读取字符串时则需要
      // const msgUint8 = new TextEncoder().encode(data);
      const hashBuffer = await crypto.subtle.digest("SHA-512", data); // hash the message
      // const hashBuffer = await CryptoJS.SHA512(data);

      console.log(hashBuffer);
      const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
      console.log(hashArray);
      const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join(""); // convert bytes to hex string

      return hashHex;
    },
    // 字符串转Arraybuffer
    strToArrayBuffer(str) {
      var array = new Uint8Array(str.length);
      for (var i = 0; i < str.length; i++) {
        array[i] = str.charCodeAt(i);
      }
      return array.buffer;
    },
    lhcComputed() {
      this.lhc1 = "";
      this.lhc2 = "";
      this.lhc22 = "";
      this.lhcarr3 = [];
      this.lhcarr4 = [];
      this.lhcarr5 = [];
      let numArr = [];
      this.lhc1 = this.codeList.join("+") + "@" + this.currentExpect;
      // this.lhc1 = "4+3+1+7+8@22225@20220824";
      let res = CryptoJS.SHA512(this.lhc1).toString();
      this.lhc22 = res.slice(0, 108);
      this.lhc2 = res.slice(108);

      this.lhcSpliceIndex.forEach((nindex, i) => {
        let obj = {
          index: i + 1,
          value: res.substr(nindex[0], 8),
        };
        this.lhcarr3.push(obj);

        let num16 = parseInt(obj.value.toUpperCase(), 16);

        let obj1 = {
          index: i + 1,
          value: num16,
        };
        numArr.push(Object.assign({}, obj1));
        this.lhcarr4.push(obj1);
      });
      // console.log(numArr, "numArr");

      numArr.sort((a, b) => Number(b.value) - Number(a.value));
      this.lhcarr5 = numArr;
    },
    syxwComputed() {
      this.syxw1 = "";
      this.syxw2 = "";
      this.syxw22 = "";
      this.syxwarr3 = [];
      this.syxwarr4 = [];
      this.syxwarr5 = [];
      let numArr = [];
      this.syxw1 = this.codeList.join("+") + "@" + this.currentExpect;
      let res = CryptoJS.SHA512(this.syxw1).toString();
      this.syxw22 = res.slice(0, 77);
      this.syxw2 = res.slice(77);

      this.syxwspliceIndex.forEach((nindex, i) => {
        let obj = {
          index: i + 1,
          value: res.substr(nindex, 5),
        };
        this.syxwarr3.push(obj);

        let num16 = parseInt(obj.value.toUpperCase(), 16);

        let obj1 = {
          index: i + 1,
          value: num16,
        };
        numArr.push(Object.assign({}, obj1));
        this.syxwarr4.push(obj1);
      });
      // console.log(numArr, "numArr");

      numArr.sort((a, b) => Number(b.value) - Number(a.value));
      this.syxwarr5 = numArr;
      // console.log(a1, a2, res);
    },
    klsf8Computed() {
      this.klsf1 = "";
      this.klsf2 = "";
      this.klsf22 = "";
      this.klsfarr3 = [];
      this.klsfarr4 = [];
      this.klsfarr5 = [];
      let numArr = [];

      this.klsf1 = this.codeList.join("+") + "@" + this.currentExpect;
      let res = CryptoJS.SHA512(this.klsf1).toString();
      this.klsf22 = res.slice(0, 100);
      this.klsf2 = res.slice(100);

      this.klsfspliceIndex.forEach((nindex, i) => {
        let obj = {
          index: i + 1,
          value: res.substr(nindex, 5),
        };
        this.klsfarr3.push(obj);

        let num16 = parseInt(obj.value.toUpperCase(), 16);

        let obj1 = {
          index: i + 1,
          value: num16,
        };
        numArr.push(Object.assign({}, obj1));
        this.klsfarr4.push(obj1);
      });
      // console.log(numArr, "numArr");

      numArr.sort((a, b) => Number(b.value) - Number(a.value));
      this.klsfarr5 = numArr;
    },
    pk10Computed() {
      this.pk1 = "";
      this.pk2 = "";
      this.pk22 = "";
      this.pkarr3 = [];
      this.pkarr4 = [];
      this.pkarr5 = [];
      let numArr = [];
      this.pk1 = this.codeList.join("+") + "@" + this.currentExpect;
      let res = CryptoJS.SHA512(this.pk1).toString();
      this.pk22 = res.slice(0, 60);
      this.pk2 = res.slice(60);

      this.splicehashArr.forEach((nindex, i) => {
        let obj = {
          index: i + 1,
          value: res.substr(nindex, 6),
        };
        this.pkarr3.push(obj);
        let num16 = parseInt(obj.value.toUpperCase(), 16);

        let obj1 = {
          index: i + 1,
          value: num16,
        };
        numArr.push(Object.assign({}, obj1));
        this.pkarr4.push(obj1);
      });
      // console.log(numArr, "numArr");
      numArr.sort((a, b) => Number(b.value) - Number(a.value));
      this.pkarr5 = numArr;
      // console.log(a1, a2, res);
    },
    // 宾果时时彩
    sscComputed() {
      this.weishuArr = [];
      this.sscArr.forEach((itemarr, index) => {
        itemarr[1] = [this.zhanweiArr[index]];
        itemarr[2].forEach((needIndex) => {
          itemarr[1].push(this.codeList[needIndex]);
        });
        let sum = 0;
        itemarr[1].forEach((num, i) => {
          if (i > 0) {
            sum += Number(num);
          }
        });

        itemarr[1].push(sum);

        let sumStr = sum + "";
        let weishu = sumStr[sumStr.length - 1];
        this.weishuArr.push(weishu);
        itemarr[1].push(weishu);
      });
      this.$forceUpdate();
    },
    // 台湾五分彩
    sscComputed1() {
      this.weishuArr = [];
      this.sscArr1.forEach((itemarr, index) => {
        itemarr[1] = [this.zhanweiArr[index]];
        itemarr[2].forEach((needIndex) => {
          itemarr[1].push(this.codeList[needIndex]);
        });
        let sum = 0;
        itemarr[1].forEach((num, i) => {
          if (i > 0) {
            sum += Number(num);
          }
        });

        itemarr[1].push(sum);

        let sumStr = sum + "";
        let weishu = sumStr[sumStr.length - 1];
        this.weishuArr.push(weishu);
        itemarr[1].push(weishu);
      });
      this.$forceUpdate();
    },
    k3Computed() {
      this.he1 = 0;
      this.he2 = 0;
      this.he3 = 0;
      let arr1 = this.codeList.filter(
        (item, index) => index >= 0 && index <= 5
      );
      let arr2 = this.codeList.filter(
        (item, index) => index >= 6 && index <= 11
      );
      let arr3 = this.codeList.filter(
        (item, index) => index >= 12 && index <= 17
      );
      arr1.forEach((num) => {
        this.he1 += Number(num);
      });
      arr2.forEach((num) => {
        this.he2 += Number(num);
      });
      arr3.forEach((num) => {
        this.he3 += Number(num);
      });
      (this.mo1 = this.he1 % 6 == 0 ? 6 : this.he1 % 6),
        (this.mo2 = this.he2 % 6 == 0 ? 6 : this.he2 % 6),
        (this.mo3 = this.he3 % 6 == 0 ? 6 : this.he3 % 6);

      this.mohe = Number(this.mo1) + Number(this.mo2) + Number(this.mo3);
    },
    pcddComputed() {
      this.he1 = 0;
      this.he2 = 0;
      this.he3 = 0;
      let arr1 = this.codeList.filter(
        (item, index) => index >= 0 && index <= 5
      );
      let arr2 = this.codeList.filter(
        (item, index) => index >= 6 && index <= 11
      );
      let arr3 = this.codeList.filter(
        (item, index) => index >= 12 && index <= 17
      );
      arr1.forEach((num) => {
        this.he1 += Number(num);
      });
      arr2.forEach((num) => {
        this.he2 += Number(num);
      });
      arr3.forEach((num) => {
        this.he3 += Number(num);
      });
      let str1 = this.he1 + "",
        str2 = this.he2 + "",
        str3 = this.he3 + "";
      this.mo1 = str1.substring(str1.length - 1);
      this.mo2 = str2.substring(str2.length - 1);
      this.mo3 = str3.substring(str3.length - 1);
      this.mohe = Number(this.mo1) + Number(this.mo2) + Number(this.mo3);
    },
    getQici() {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        pagenum: 1,
        pagecount: 30,
        roomeng: this.currentroomeng,
      };
      if (this.currentFrequency == "day") {
        let year = new Date().getFullYear();
        obj.begindate = year-1 + "0101";
        obj.enddate = this.splitDate(this.getBeforeDate(0));
      } else {
        // 分钟级
        obj.begindate = this.splitDate(this.getBeforeDate(0));
        obj.enddate = this.splitDate(this.getBeforeDate(0));
      }
      this.$http
        .post("getlotteryhis", obj)
        .then((res) => {
          if (res.Status) {
            let resObj = JSON.parse(res.Msg);
            this.qiciList = resObj.data;
            this.currentExpect = this.qiciList[0].Expect;
            // console.log(this.qiciList);

            this.gethaoyuan();
          } else {
            this.$message.warning(res.Msg);
          }
        })
        .catch((err) => {});
    },
    updateQici() {
      // console.log(this.currentExpect);
      this.gethaoyuan();
    },
    gethaoyuan() {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        expect: this.currentExpect,
        // roomeng: "twbingo",
        roomeng: this.currentroomeng,
        // roomid: this.currentroomid,
      };
      this.$http.post("getopencoderes", obj).then((res) => {
        if (res.Status) {
          let resObj = JSON.parse(res.Msg);
          this.haoyuanName = resObj.nameeng;
          let arr = resObj.Opencode.split(",");
          // console.log(arr, "号源arr",arr.length);
          let arr1 = [];
          arr.forEach((num) => {
            arr1.push(Number(num));
          });

          if (arr1.length > 20) {
            this.isBingGo = true;
            arr1.splice(arr1.length - 1);
          } else {
            this.isBingGo = false;
          }
  
          this.codeList = arr1;
          //   this.codeList  console.log(resObj, "号源信息");
        } else {
          this.$message.warning(res.Msg);
        }
      });
    },
    closeYanzheng() {
      this.$store.commit("SETYANZHENG", false);
    },
    // 复制
    clickCopy(textToCopy) {
      // navigator clipboard 需要https等安全上下文
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard 向剪贴板写文本
        this.$message.success("复制成功");
        return navigator.clipboard.writeText(textToCopy);
      } else {
        // 创建text area
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // 使text area不在viewport，同时设置不可见
        textArea.style.position = "absolute";
        textArea.style.opacity = 0;
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // 执行复制命令并移除文本框
          document.execCommand("copy") ? res() : rej();
          textArea.remove();
          this.$message.success("复制成功");
        });
      }
    },
  },
};
</script>
<style   scoped>
.addbiaoshi {
  font-size: 15px;
  position: relative;
  top: -4px;
  left: 4px;
}
.lhc_con4 p {
  width: 25px;
  height: 25px;
  margin-left: 10px;
}
.type-lhc p,
.type-ssg p,
.type-pks p {
  position: relative;
  top: 3px;
  /* left: 3px; */
  display: inline-block;
  margin-right: 0;
}
.small {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;

  box-sizing: border-box;
}
.large {
  width: 104px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  box-sizing: border-box;
}
.klsf_con4 p {
  width: 24px;
  height: 23px;
  margin-right: 10px;
}
.pk_con4 p {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.pk_con4,
.klsf_con4 {
  margin-left: 10px;
  width: 670px;
  max-width: 670px;
  display: flex;
}
.pk_con3 section {
  display: inline-block;
  border-right: 1px solid #c5c1c1;
  border-bottom: 1px solid #c5c1c1;
}
.pk_con3 {
  margin-left: 10px;
  width: 670px;
  max-width: 670px;
  display: flex;
  flex-wrap: wrap;
  /* height: 60px; */
  border-top: 1px solid #c5c1c1;
  border-left: 1px solid #c5c1c1;
  background: #e5e5e5;
  /* box-sizing: border-box; */
}

.pk10_con2 {
  width: 670px !important;
  max-width: 670px;
  padding: 3px;
  word-break: break-all;
  min-height: 40px;
  margin-left: 10px;
  background: #e5e5e5;
  border: 1px solid lightgrey;
  box-sizing: border-box;
}
.pk_con1 {
  padding: 4px;
  margin-right: 4px;
  margin-left: 10px;
  width: 600px;
  height: 35px;
  /* min-height: 40px; */
  background: #e5e5e5;
  border: 1px solid #e4e4e5;
  -webkit-touch-callout: auto; /* iOS Safari */

  -webkit-user-select: auto; /* Chrome/Safari/Opera */

  -khtml-user-select: auto; /* Konqueror */

  -moz-user-select: auto; /* Firefox */

  -ms-user-select: auto; /* Internet Explorer/Edge */

  user-select: auto;
}
.r_label {
  display: inline-block;
  width: 100px;
  margin-left: 20px;
  text-align: right;
  /* border: 1px solid red; */
}
.sx {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  text-align: center;
  line-height: 20px;
  background: rgb(155, 19, 19);
  color: #fff;
  text-align: center;
  font-weight: 700;
}
.pk10_row {
  width: 99%;
  margin: 10px auto;
  display: flex;
  align-items: center;
}
.pk10_suanfa {
  padding-top: 10px;
  padding-bottom: 10px;
}
.ssc_result {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.ssc_result p {
  margin: 0 5px;
}
.type-ssc p {
  display: inline-block;
}
.w80 {
  width: 80px;
}
.skin-blue .com-table th {
  background: linear-gradient(180deg, #fff 0, #fff 50%, #fef4ea 51%, #fef4ea);
}
.skin-red .com-table th {
  background: linear-gradient(180deg, #fff, #fff 50%, #ffefee 51%, #ffefee);
}
.skin-brown .com-table th {
  background: linear-gradient(180deg, #fff, #fff 50%, #fdeee1 51%, #fdeee1);
}
.com-table th {
  padding: 4px 0;
  border-right: 1px solid #b9c2cb;
  border-bottom: 1px solid #b9c2cb;
}
.skin-blue .com-table tr td:nth-of-type(1) {
  background: #c3d9f1;
  font-weight: 700;
}
.skin-red .com-table tr td:nth-of-type(1) {
  background: #fff5f5;
  font-weight: 700;
}
.skin-brown .com-table tr td:nth-of-type(1) {
  background: #dfb55e;
  font-weight: 700;
}
.com-table {
  margin: 20px auto;
  width: 90%;
  text-align: center;
  vertical-align: middle;
  border-top: 1px solid #b9c2cb;
  border-left: 1px solid #b9c2cb;
}
.com-table td {
  padding: 4px 0;
  /* display: flex; */
  /* justify-content: center; */
  text-align: center;
  border-right: 1px solid #b9c2cb;
  border-bottom: 1px solid #b9c2cb;
}
.com-table td p {
  display: inline-block;
}
.pcdd_row.pcddopen .ball:after {
  position: absolute;
  left: 30px;
  top: 3px;
  content: "+";
  color: #000;
}
.pcdd_row.pcddopen .ball:nth-of-type(3):after {
  content: "=";
}
.line_th.zh {
  position: relative;
  left: -8px;
}
.pcdd_row.nomt {
  margin: 0 auto;
  position: relative;
  /* top: -2px; */
}
.pcdd_row.mt20 {
  margin-top: 20px;
  margin-bottom: 0;
}
.pcdd_row.center {
  justify-content: center;
}
.bankuang.w500 {
  /* margin-top: ; */
  width: 430px;
  position: relative;
  left: -10px;
}
.moweishu .after {
  position: absolute;
  right: -90px;
  top: -5px;
  width: 40px;
  height: 20px;
  font-size: 35px;
  line-height: 20px;
  /* border: 1px solid red; */
}
.moweishu {
  position: relative;
  width: 80px;
  display: inline-block;
  border: 3px solid rgb(31, 136, 31);
}
.line_th {
  display: inline-block;
  width: 3px;
  height: 20px;
  background: rgb(31, 136, 31);
}
.red {
  font-weight: 600;
}

.bankuang {
  width: 180px;
  /* height: 22px; */
  border: 3px solid rgb(31, 136, 31);
  border-top: none;
  text-align: center;
  font-size: 14px;
}
.bankuang.nobor {
  border: 3px solid #fff;
}
.bankuang:nth-of-type(2) {
  margin-left: 30px;
}
.bankuang:nth-of-type(3) {
  margin-left: 30px;
}
.pcdd_row {
  display: flex;
  /* justify-content: space-between; */
  width: 640px;
  height: 22px;
  margin: 5px auto;
  /* border: 1px solid red; */
}
.pcdd_suanfa {
  position: relative;
  left: -25px;
}
.haoma {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-indent: 20px;
  background: #deded2;
  /* box-shadow: 0px 0 5px #999; */
}
.haoma p {
  margin: 0 5px;
  text-indent: 0;
}
.qicispan {
  margin-left: 10px;
  margin-right: 5px;
}
.skin-brown .yz_header {
  background: #dfb55e;
}
.skin-red .yz_header {
  background: #6a1f2d;
}
.skin-blue .yz_header {
  background: #2662a6;
}
.yz_header {
  height: 30px;
  color: #fff;
  line-height: 30px;
  text-indent: 10px;
  position: relative;
}
.yz_header .guanbi {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 35px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #2662a6;
  text-indent: 0;
  background: #fff;
}
.yz_box {
  position: absolute;
  left: 0;
  top: 32px;
  min-width: 830px;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 2;
  box-sizing: border-box;
}
.zhushi {
  margin-top: 20px;
  text-align: center;
  color: rgb(220, 19, 19);
  font-size: 15px;
  text-indent: 100px;
}
</style>